// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-detail-jsx": () => import("./../../../src/pages/blog-detail.jsx" /* webpackChunkName: "component---src-pages-blog-detail-jsx" */),
  "component---src-pages-blog-listing-jsx": () => import("./../../../src/pages/blog-listing.jsx" /* webpackChunkName: "component---src-pages-blog-listing-jsx" */),
  "component---src-pages-case-studies-jsx": () => import("./../../../src/pages/case-studies.jsx" /* webpackChunkName: "component---src-pages-case-studies-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-style-guide-jsx": () => import("./../../../src/pages/style-guide.jsx" /* webpackChunkName: "component---src-pages-style-guide-jsx" */),
  "component---src-templates-blog-detail-jsx": () => import("./../../../src/templates/blog-detail.jsx" /* webpackChunkName: "component---src-templates-blog-detail-jsx" */),
  "component---src-templates-case-studies-detail-jsx": () => import("./../../../src/templates/case-studies-detail.jsx" /* webpackChunkName: "component---src-templates-case-studies-detail-jsx" */),
  "component---src-templates-news-detail-jsx": () => import("./../../../src/templates/news-detail.jsx" /* webpackChunkName: "component---src-templates-news-detail-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

